$small: 640px;
$medium: 960px;//60rem * 16px;
$large: 1280px;
$wide: 1500px;

$small-max-width: 100%;
$medium-max-width: 100%;
$large-max-width: 70rem;
$wide-max-width: 70rem;

$global-max-width: 60rem;


$mq-map: (
  small: "(max-width: #{$small - 1px})",
  up-to-medium:  "(max-width: #{$medium})",
  medium-and-up:  "(min-width: #{$small})",
  large-and-up: "(min-width: #{$medium})",
  wide-and-up: "(min-width: #{$large})"
);

@mixin on($variation: up-to-medium) {
  $mq: map-get($mq-map, $variation);
  @media #{$mq} {
    @content;
  }
}