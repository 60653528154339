#popup-image{
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgb(0,0,0);
  background: rgba(0,0,0,0.7);
  padding-top: 5%;

  #close-popup{
    position: absolute;
    top: 15px;
    right: 35px;
    color: #ffffff;
    font-size: 30px;
  }
  #image-popup{
    margin: auto;
    display: block;
  }
}