.information-tooltip {

  cursor:pointer;
  margin: 0.5rem 0 0 0.5rem;
  //position:relative;

  &__icon {
    height: 16px;
    width: 16px;
    fill: $primary-color;
  }
  &__placeholder {
    height: 16px;
    width: 16px;
  }

  &__bubble {
    display: none;
    width: 230px;
    max-width: 230px;
    min-height: 105px;
    //max-height: 105px;
    padding: 16px;
    background-color: #fff;
    box-shadow: $block-box-shadow;
    pointer-events: none;
    transition:opacity 250ms ease-out;
    //border-radius: 3px;
    cursor: default;
    margin: 12px;
    z-index: 9999;
    //pointer-events: none;

    &:before {
      background-color: #fff;
      content:'';
      display: block;
      position:absolute;
      top: 50%;
      left: -6px;
      width: 12px;
      height: 12px;
      transform: translateY(-50%) rotate(45deg);
      z-index: 999;
    }
    &:after {
      background-color: #fff;
      box-shadow: $block-box-shadow;
      content:'';
      display: block;
      position:absolute;
      top:50%;
      left: -6px;
      width: 12px;
      height: 12px;
      transform: translateY(-50%) rotate(45deg);
      z-index: -1;
    }

    &__title {
      font-weight: bold;
      color: $text-color-headings;
    }
    &__content {
      display: block;
      margin-top: 5px;
      color: $text-color-base;
    }

    &__mask {
      display: block;
      top: 0;
      height: 100%;
      position: absolute;
      width: 16px;
      z-index: 9;
      background: #fff;
    }


    &[x-placement="right"]:before,
    &[x-placement="right"]:after {
      left: -6px;
      right: auto;
    }
    &[x-placement="right"] &__mask {
      left: 0;
    }

    &[x-placement="left"]:before,
    &[x-placement="left"]:after  {
      right: -6px;
      left: auto;
    }
    &[x-placement="left"] &__mask {
      right: 0;
    }

    &--active {
      display: inline-block;
      pointer-events: all !important;
    }

  }

  .hour-padding {
    padding-left: 20px;
  }
}